// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  TEACHER: '/teacher',
  STUDENT: '/student',
  ACCOUNT: '/account',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    supabase: {
      login: `${ROOTS.AUTH}/supabase/login`,
      verify: `${ROOTS.AUTH}/supabase/verify`,
      register: `${ROOTS.AUTH}/supabase/register`,
      newPassword: `${ROOTS.AUTH}/supabase/new-password`,
      forgotPassword: `${ROOTS.AUTH}/supabase/forgot-password`,
      pending: `${ROOTS.AUTH}/supabase/pending`,
    },
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  account: {
    setting: `${ROOTS.ACCOUNT}/setting`,
  },
  teacher: {
    academy: {
      student: `${ROOTS.TEACHER}/academy/student`,
      clinic: `${ROOTS.TEACHER}/academy/clinic`,
      classroom: `${ROOTS.TEACHER}/academy/classroom`,
    },
    worksheet: {
      list: `${ROOTS.TEACHER}/worksheet/list`,
      examsheet: `${ROOTS.TEACHER}/worksheet/examsheet`,
      homeworksheet: `${ROOTS.TEACHER}/worksheet/homeworksheet`,
    },
    questionbank: {
      list: `${ROOTS.TEACHER}/questionbank/list`,
      upload: `${ROOTS.TEACHER}/questionbank/upload`,
    },
  },
  student: {
    learn: {
      myworkbook: `${ROOTS.STUDENT}/learn/myworkbook`,
      clinic: `${ROOTS.STUDENT}/learn/clinic`,
      solve: {
        clinic: (clinicId) => `${ROOTS.STUDENT}/learn/solve/clinic/${clinicId}`,
        workbook: `${ROOTS.STUDENT}/learn/solve/workbook`,
      },
    },
    message: {
      aitutor: `${ROOTS.STUDENT}/message/aitutor`,
      teacher: `${ROOTS.STUDENT}/message/teacher`,
    },
    history: {
      report: `${ROOTS.STUDENT}/history/report`,
      analysis: `${ROOTS.STUDENT}/history/analysis`,
    },
  },
};
