import { radioClasses } from '@mui/material/Radio';

// ----------------------------------------------------------------------

export function radio(theme) {
  return {
    // CHECKBOX, RADIO, SWITCH
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 500,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const { color } = ownerState;

          return {
            padding: theme.spacing(1),
            color: theme.palette.primary.main,
            ...(color === 'default' && {
              [`&.${radioClasses.checked}`]: {
                color: theme.palette.primary.main,
              },
            }),
            [`&.${radioClasses.disabled}`]: {
              color: theme.palette.text.primary,
            },
          };
        },
      },
    },
  };
}
