import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import useTheme from '@mui/system/useTheme';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export default function NavInfo() {
  const { user } = useAuthContext();
  const theme = useTheme();
  return (
    <Stack
      sx={{
        px: 3.5,
        pt: 4,
        pb: 7,
        textAlign: 'right',
      }}
    >
      <Stack sx={{ width: 1 }} alignItems="end" justifyContent="flex-end">
        <Box>
          <Avatar
            src={user?.avatarURL}
            sx={{
              width: 56,
              height: 56,
              border: `solid 2px ${theme.palette.background.paper}`,
            }}
          />
        </Box>
        <Stack
          sx={{ mb: 0, mt: 1, width: 1 }}
          spacing={0.5}
          direction="row"
          justifyContent="flex-end"
          alignItems="baseline"
        >
          <Typography variant="subtitle2" letterSpacing="2px" noWrap>
            {user?.role === 'Student' && '학생'}
            {user?.role === 'Teacher' && '선생님'}
          </Typography>
          <Typography
            variant="h3"
            letterSpacing="2px"
            noWrap
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis', // Use ellipsis to indicate overflow
              maxWidth: '145px', // Adjust max width as needed
            }}
          >
            {user?.user_name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            {user?.ai_credit}
          </Typography>
          <SvgIcon
            inheritViewBox // This prop might be needed depending on your SVG to ensure it scales correctly
            sx={{ fontSize: '1.25rem' }} // Adjust the size as needed to match the typography
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="7" fill="#6366F1" />
              <path
                d="M10.9282 12L10.0478 11.3115C9.75864 11.4499 9.44391 11.5592 9.10367 11.6393C8.76342 11.7195 8.39341 11.7596 7.99362 11.7596C6.64115 11.7596 5.63743 11.388 4.98246 10.6448C4.32749 9.89435 4 8.98725 4 7.9235C4 6.7796 4.34875 5.83971 5.04625 5.10383C5.74375 4.36794 6.72621 4 7.99362 4C9.3546 4 10.3626 4.37523 11.0175 5.12568C11.6725 5.87614 12 6.78324 12 7.84699C12 8.39344 11.9149 8.89982 11.7448 9.36612C11.5832 9.82514 11.3493 10.2222 11.0431 10.5574L11.8979 11.2678L10.9282 12ZM5.72249 7.91257C5.72249 8.69217 5.90537 9.32969 6.27113 9.82514C6.6369 10.3206 7.2068 10.5683 7.98086 10.5683C8.14248 10.5683 8.30409 10.5537 8.46571 10.5246C8.62733 10.4882 8.77618 10.4444 8.91228 10.3934L7.85327 9.55191L8.97608 8.81967L9.89474 9.5847C10.0138 9.37341 10.1074 9.12568 10.1754 8.84153C10.2435 8.55009 10.2775 8.21858 10.2775 7.84699C10.2775 7.07468 10.0946 6.4408 9.72887 5.94536C9.3631 5.44991 8.79319 5.20219 8.01914 5.20219C7.33865 5.20219 6.78575 5.44627 6.36045 5.93443C5.93514 6.42259 5.72249 7.08197 5.72249 7.91257Z"
                fill="#FFFFFF"
              />
            </svg>
          </SvgIcon>
        </Stack>
      </Stack>
    </Stack>
  );
}
