import { useState, useCallback } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';

import { enqueueSnackbar } from 'src/components/snackbar';

const matchIsString = (text) => typeof text === 'string';
export function matchIsNumeric(text) {
  const isNumber = typeof text === 'number';
  const isString = matchIsString(text);
  return (isNumber || (isString && text !== '')) && !Number.isNaN(Number(text));
}

const validateChar = (value, index) => {
  if (value === '' || value === null) {
    return true;
  }
  return matchIsNumeric(value);
};
const NoTeacherDialog = (props) => {
  const router = useRouter();
  const { logout, user, requestTeacherConnection } = useAuthContext();
  const isPending = user?.studentInfo?.pending;
  const [loading, setLoading] = useState(false);
  const handleConnectToTeacher = async (teacherCode) => {
    try {
      setLoading(true);
      await requestTeacherConnection(teacherCode);
      enqueueSnackbar('선생님에게 연결 요청을 보냈습니다. 선생님이 승인하면 연결됩니다.', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(
        `오류가 발생했습니다. ${err.message} 코드를 다시 확인하거나 다시 시도 해 주세요`,
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };
  const [otp, setOtp] = useState('');
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      router.replace('/');
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <Card sx={{ p: 3.75 }}>
        <Stack spacing={2}>
          <Typography variant="h5" fontWeight={500} color="primary.main">
            {isPending && '선생님 승인을 기다리는 중입니다.'}
            {!isPending && '선생님과 연결되지 않았습니다.'}
          </Typography>
          <Typography variant="body2" fontWeight={500} color="primary.main">
            {isPending && '선생님이 승인을 완료할 때 까지 기다려주세요'}
            {!isPending && '선생님께서 제공한 8자리 인증 코드를 입력해주세요.'}
          </Typography>

          {!isPending && (
            <MuiOtpInput
              value={otp}
              onChange={handleChange}
              length={8}
              validateChar={validateChar}
            />
          )}
          <Stack spacing={2} direction="row" sx={{ justifyContent: 'end' }}>
            {!isPending && (
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={otp.length !== 8}
                onClick={() => handleConnectToTeacher(parseInt(otp, 10))}
                loading={loading}
              >
                연결 요청하기
              </LoadingButton>
            )}
            <Button variant="outlined" onClick={handleLogout}>
              로그아웃
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Dialog>
  );
};

export default NoTeacherDialog;
