export function container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(5), // Custom padding on the left
          paddingRight: theme.spacing(5), // Custom padding on the right
          paddingTop: theme.spacing(5.5), // Custom padding on the top
          paddingBottom: theme.spacing(5.5), // Custom padding on the bottom
        },
      },
    },
  };
}
