import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Logo from 'src/components/logo/logo';
import { varBounce, MotionContainer } from 'src/components/animate';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, sx }) {
  const { user } = useAuthContext();
  const currentRole = user?.role;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Logo
            width={120}
            sx={{
              my: { xs: 2, sm: 4 },
            }}
          />
        </m.div>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }} color="primary.main">
            접근이 거부되었습니다.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography color="primary.main">
            죄송합니다.
            <br />이 페이지에 액세스 할 수 있는 권한이 없습니다.
            <br />
            이것이 오류라고 생각된다면, 관리자에게 문의해주세요
          </Typography>
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node.isRequired,
  hasContent: PropTypes.bool.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  sx: PropTypes.object.isRequired
}
