import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const FullLogo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------

  const logo = (
    <Box component="img" src="/logo/logo_full.svg" sx={{ height: 40, cursor: 'pointer', ...sx }} />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

FullLogo.propTypes = {
  disabledLink: PropTypes.bool.isRequired,
  sx: PropTypes.object.isRequired
}

export default FullLogo;
