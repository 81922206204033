import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import Stack from '@mui/material/Stack';

import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, slotProps, sx, ...other }) {
  return (
    <Stack
      component="nav"
      id="nav-section-horizontal"
      direction="row"
      alignItems="center"
      spacing={`${slotProps?.gap || 6}px`}
      sx={{
        mx: 'auto',
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          items={group.items}
          slotProps={slotProps}
          groupRoles={group.groupRoles}
        />
      ))}
    </Stack>
  );
}

NavSectionHorizontal.propTypes = {
  data: PropTypes.array.isRequired,
  slotProps: PropTypes.object.isRequired,
  sx: PropTypes.object.isRequired
}

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

function Group({ items, slotProps, groupRoles }) {
  const shouldRenderGroup = useCallback(() => {
    if (!groupRoles) return true;

    return groupRoles.includes(slotProps?.currentRole);
  }, [groupRoles, slotProps?.currentRole]);
  if (!shouldRenderGroup()) {
    return null;
  }
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title} data={list} depth={1} slotProps={slotProps} />
      ))}
    </>
  );
}

Group.propTypes = {
  groupRoles: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  slotProps: PropTypes.object.isRequired
}
