import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { useRouter } from 'src/routes/hooks';

import FullLogo from 'src/components/full-logo';

import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

export default function HeaderHome() {
  const theme = useTheme();
  const router = useRouter();
  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          ml: { lg: 11 },
          mr: { lg: 11 },
          my: { lg: 6.5 },
          height: {
            xs: HEADER.H_HOME_MOBILE,
            md: HEADER.H_HOME_DESKTOP,
          },

          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
        }}
      >
        <FullLogo sx={{ height: { lg: 49, xs: 25, md: 37.5 } }} />
        <Stack direction="row" spacing={{ md: 3, xs: 1 }} alignItems="center">
          <Button
            variant="contained"
            size="medium"
            sx={{ px: { lg: 7, xs: 3, md: 5 }, whiteSpace: 'nowrap' }}
            onClick={() => router.push('/auth/supabase/login')}
          >
            로그인
          </Button>
          <Button
            variant="outlined"
            sx={{ px: { lg: 6, xs: 2, md: 5 }, whiteSpace: 'nowrap' }}
            onClick={() => router.push('/auth/supabase/register')}
          >
            회원가입
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
