import moment from 'moment-timezone';

const workbookToArray = (startDate, weekCount, daysPerWeek, selectedWeekDays) => {
  if (!startDate || !weekCount || !daysPerWeek || !selectedWeekDays) {
    return [];
  }
  try {
    const dateArray = [];
    const startDay = moment(startDate).tz('Asia/Seoul');
    const endDate = startDay.clone();
    const totalDays = weekCount * daysPerWeek;
    let dayCount = 0;
    let weekNumber = 1;
    let dayNumber = 1;
    while (dayCount < totalDays) {
      if (selectedWeekDays.includes(endDate.format('dd'))) {
        dateArray.push({ week: weekNumber, day: dayNumber, date: endDate.format('YYYY-MM-DD') });
        dayCount += 1;
        dayNumber += 1;
        if (dayNumber > daysPerWeek) {
          dayNumber = 1;
          weekNumber += 1;
        }
      }
      endDate.add(1, 'days');
    }
    return dateArray;
  } catch (e) {
    return [];
  }
};

export default workbookToArray;
