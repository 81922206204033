import PropTypes from 'prop-types';

import { useAuthContext } from 'src/auth/hooks';

import NoTeacherDialog from './function-guard-dialogs/no-teacher-dialog';
// ----------------------------------------------------------------------

export default function FunctionGuard({ children, sx }) {
  const { user } = useAuthContext();
  const isTeacherNotConnected =
    user?.role === 'Student' && user?.studentInfo?.teacher_info === null;

  return (
    <>
      {isTeacherNotConnected && <NoTeacherDialog />}
      {children}
    </>
  );
}

FunctionGuard.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object.isRequired
}
