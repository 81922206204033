import * as React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '@mui/material';

const CustomPlusIcon = ({ iconColor = '#6366F1', ...props }) => (
  <SvgIcon sx={{ width: '16px', height: 'auto' }} {...props} viewBox="0 0 16 16">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6668 8.66683H8.66683V12.6668H7.3335V8.66683H3.3335V7.3335H7.3335V3.3335H8.66683V7.3335H12.6668V8.66683Z"
        fill={iconColor}
      />
    </svg>
  </SvgIcon>
);

export default CustomPlusIcon;

CustomPlusIcon.propTypes = {
  iconColor: PropTypes.string.isRequired, // Allow any prop types
}
