import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import CustomPlusIcon from 'src/components/custon-icons/plus-icon';
import StukiGrayscaleLogo from 'src/components/logo/stuki-grayscale-logo';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const homePage = pathname === '/';

  const theme = useTheme();
  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <StukiGrayscaleLogo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
          © All rights reserved
          <br /> by
          <Link href="https://stuki.co.kr/"> stuki </Link>
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Grid container alignItems="center" sx={{ py: 2.25, mx: 9 }}>
        <Grid xs={12} md={6}>
          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems="center">
            <StukiGrayscaleLogo />
            <Typography variant="subtitle3" fontWeight={400} color={theme.palette.grey[45]}>
              마이워크북(myworkbook)
              <br />
              경기 김포시 김포한강10로 133번길 107, 디원시티시그니처 B코어 928호
              <br />
              사업자등록번호 874-81-00827 | 통신판매업신고 2022-경기김포-2837
              <br />
              (주)스투키 | 홍인혁 | Copyright © stuki. All rights reserved.
            </Typography>
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Stack
            spacing={5}
            direction="row"
            justifyContent={{ xs: 'center', md: 'end' }}
            sx={{ mt: { xs: 2 } }}
          >
            <Stack spacing={2} direction="row">
              <CustomPlusIcon />
              <Link
                component={RouterLink}
                color={theme.palette.grey[45]}
                variant="body1"
                noWrap
                fontWeight={500}
                href="/terms-and-conditions"
              >
                이용약관
              </Link>
            </Stack>
            <Stack spacing={2} direction="row">
              <CustomPlusIcon />
              <Link
                component={RouterLink}
                color={theme.palette.grey[45]}
                variant="body1"
                fontWeight={500}
                noWrap
              >
                개인정보 처리방침
              </Link>
            </Stack>
            <Stack spacing={2} direction="row">
              <CustomPlusIcon />
              <Link
                component={RouterLink}
                color={theme.palette.grey[45]}
                variant="body1"
                fontWeight={500}
                noWrap
              >
                문의하기
              </Link>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );

  return homePage ? simpleFooter : mainFooter;
}
