import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  home: icon('ic_home'),
  learn: icon('ic_learn'),
  message: icon('ic_message'),
  result: icon('ic_result'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        groupRoles: ['Student', 'Teacher'],
        items: [
          {
            title: '홈',
            path: paths.dashboard.root,
            icon: ICONS.home,
          },
        ],
      },
      {
        groupRoles: ['Teacher'],
        items: [
          {
            title: '수업관리',
            path: '/teacher/lesson',
            icon: ICONS.learn,
            children: [
              { title: '학급관리', path: paths.student.learn.myworkbook },
              { title: '학생관리', path: paths.student.learn.clinic },
            ],
          },
        ],
      },
      {
        groupRoles: ['Teacher'],
        items: [
          {
            title: '학습지관리',
            path: '/teacher/workbook',
            icon: ICONS.learn,
            children: [
              { title: '학습지관리메뉴1', path: paths.student.learn.myworkbook },
              { title: '학습지관리메뉴2', path: paths.student.learn.clinic },
            ],
          },
        ],
      },
      {
        groupRoles: ['Teacher'],
        items: [
          {
            title: '질문관리',
            path: '/teacher/message',
            icon: ICONS.learn,
            children: [
              { title: '질문관리메뉴1', path: paths.student.learn.myworkbook },
              { title: '질문관리메뉴2', path: paths.student.learn.clinic },
            ],
          },
        ],
      },
      {
        groupRoles: ['Teacher'],
        items: [
          {
            title: '학원관리',
            path: '/teacher/academy',
            icon: ICONS.learn,
            children: [
              { title: '학원관리메뉴1', path: paths.student.learn.myworkbook },
              { title: '학원관리메뉴2', path: paths.student.learn.clinic },
            ],
          },
        ],
      },
      {
        groupRoles: ['Teacher'],
        items: [
          {
            title: '큐스토어',
            path: '/teacher/store',
            icon: ICONS.learn,
            children: [
              { title: '학습지관리메뉴1', path: paths.student.learn.myworkbook },
              { title: '학습지관리메뉴2', path: paths.student.learn.clinic },
            ],
          },
        ],
      },
      {
        groupRoles: ['Student'],
        items: [
          {
            title: '학습하기',
            path: '/student/learn',
            icon: ICONS.learn,
            children: [
              { title: '마이워크북', path: paths.student.learn.myworkbook },
              { title: '클리닉', path: paths.student.learn.clinic },
            ],
          },
        ],
      },
      {
        groupRoles: ['Student'],
        items: [
          {
            title: '질문하기',
            path: '/student/message',
            icon: ICONS.message,
            children: [
              { title: 'AI튜터에게', path: paths.student.message.aitutor },
              { title: '선생님에게', path: paths.student.message.teacher },
            ],
          },
        ],
      },
      {
        groupRoles: ['Student'],
        items: [
          {
            title: '결과보기',
            path: '/student/history',
            icon: ICONS.result,
            children: [
              { title: '보고서', path: paths.student.history.report },
              { title: '데이터분석', path: paths.student.history.analysis },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}
