import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, width = 40, ...other }, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------

  const logo = (
    <Box
      component="img"
      src="/logo/logo_single.svg"
      sx={{ width, height: 'auto', cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool.isRequired,
  sx: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
}

export default Logo;
