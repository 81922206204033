import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { useResponsive } from 'src/hooks/use-responsive';

import Footer from '../common/footer';
import { HEADER } from '../config-layout';
import Header from '../common/header-home';

// ----------------------------------------------------------------------
const SPACING = 8;

export default function HomeLayout({ children, sx, ...other }) {
  const lgUp = useResponsive('up', 'lg');
  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_HOME_MOBILE + SPACING}px`,
          ...(lgUp && {
            px: 2,
            pt: `${HEADER.H_HOME_DESKTOP + SPACING}px`,
          }),

          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>

      <Footer />
    </>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object.isRequired
}
