import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, useReducer, useCallback } from 'react';

import { paths } from 'src/routes/paths';

import workbookToArray from 'src/components/workbook-to-date-array/workbook-to-date-array';

import { supabase } from './lib';
import { AuthContext } from './auth-context';
// 유저 정보 저장용 초기 상태 및 리듀서
const initialState = {
  user: null,
  loading: true,
  authInitialized: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'AUTH_STATE_CHANGED') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'AUTH_INITIALIZED') {
    return {
      ...state,
      authInitialized: true,
    };
  }
  if (action.type === 'SUBMIT_WORKBOOK') {
    return {
      ...state,
      user: {
        ...state.user,
        studentInfo: {
          ...state.user.studentInfo,
          curriculum_solve_info: [
            ...state.user.studentInfo.curriculum_solve_info,
            {
              day_index: action.payload.day_index,
              week_index: action.payload.week_index,
              progress_history_id: action.payload.progress_history_id,
            },
          ],
        },
      },
    };
  }
  if (action.type === 'NEW_CLINIC_ASSIGNED') {
    // in submit workbook function, if clinic_id is not null, dispatches this action
    return {
      ...state,
      user: {
        ...state.user,
        studentInfo: {
          ...state.user.studentInfo,
          clinic_info: [
            ...state.user.studentInfo.clinic_info,
            {
              assign_id: action.payload.clinic_id,
              week_index: action.payload.week_index,
              day_index: action.payload.day_index,
              assigned_date: action.payload.assigned_date,
              title: action.payload.title,
              finished: false,
            },
          ],
        },
      },
    };
  }

  if (action.type === 'SUBMIT_CLINIC') {
    // input payload is clinic_id, find it from studentInfo.clinic_info and set object with assign_id = clinic_id's finished key to true
    return {
      ...state,
      user: {
        ...state.user,
        studentInfo: {
          ...state?.user?.studentInfo,
          clinic_info: state?.user?.studentInfo?.clinic_info?.map((clinic) => {
            if (clinic.assign_id === action.payload.clinic_id) {
              return {
                ...clinic,
                finished: true,
              };
            }
            return clinic;
          }),
        },
      },
    };
  }
  if (action.type === 'CREDIT_CHANGED') {
    return {
      ...state,
      user: {
        ...state.user,
        ai_credit: state.user.ai_credit + action.payload.credit,
      },
    };
  }
  if (action.type === 'UPDATE_AVATAR') {
    return {
      ...state,
      user: {
        ...state.user,
        avatarURL: action.payload.avatarURL,
      },
    };
  }
  return state;
};

// ------------------- ---------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  // 초창기에는 DB가 변경될 가능성이 높으니 state로 캐싱을 하고, 추후 정식 서비스시에 IndexedDB로 변경할 예정, LocalStorage는 공간이 적어서 불가능
  const [questionCache, setQuestionCache] = useState({});
  const [historyCache, setHistoryCache] = useState({});

  const initialize = useCallback(async () => {
    try {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error || !session) {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });

        throw error;
      }
      const userData = await getUserRow();
      if (userData?.role === 'Student') {
        const studentInfo = await getStudentInfo();
        if (!studentInfo) throw new Error('학생 정보를 가져오는데 실패했습니다.');
        userData.studentInfo = studentInfo;
        const { curriculum_info } = studentInfo;
        if (curriculum_info) {
          const { start_date, week_count, days_per_week, selected_weekdays } = curriculum_info;
          curriculum_info.curriculumArray = workbookToArray(
            start_date,
            week_count,
            days_per_week,
            selected_weekdays
          );
        }
      }
      dispatch({
        type: 'INITIAL',
        payload: {
          user: {
            ...userData,
            avatarURL: `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/avatar/${
              userData?.user_id
            }.jpg`,
          },
        },
      });
      dispatch({ type: 'AUTH_INITIALIZED' });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
    dispatch({ type: 'AUTH_INITIALIZED' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange(() => {
      initialize();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 계정 및 인증 관련 함수들
  const login = useCallback(async (email, password) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: null,
        },
      });
      console.error(error);
      throw error;
    } else {
      await initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const register = useCallback(async (email, password, user_name, role) => {
    try {
      const { data: userData, error: userError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            role,
            user_name,
          },
        },
      });
      if (userError) throw userError;
      return userData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.error(error);
      throw error;
    }

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const forgotPassword = useCallback(async (email) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}${paths.auth.supabase.newPassword}`,
    });

    if (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const updatePassword = useCallback(async (password) => {
    const { error } = await supabase.auth.updateUser({ password });

    if (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getUserRow = useCallback(async (userId) => {
    const { data, error } = await supabase.rpc('new_get_user_row');
    if (error) throw error;
    return data;
  }, []);

  // ----------------------------------------------------------------------
  // 공통 함수들
  // getContextQuestionData : 문제를 가져오는 함수. 이 함수는 캐싱을 하여 같은 문제를 여러번 불러오지 않도록 함. db에서 문제는 절대 변하지 않으니 invalidate를 하지 않음, 따라서 useState만으로 충분함
  const getContextQuestionData = useCallback(
    async (contextId) => {
      if (questionCache[contextId]) {
        return questionCache[contextId];
      }
      const { data, error } = await supabase.rpc('new_get_context_data', {
        context_id_input: contextId,
      });
      if (error) throw error;
      setQuestionCache((prev) => ({ ...prev, [contextId]: { context_id: contextId, ...data } }));
      return data;
    },
    [questionCache]
  );

  // getSolveData : 해당 워크북 또는 클리닉의 문제 풀이 데이터를 가져옴.
  const getSolveData = useCallback(
    async (progressOrAssignmentId, solveType) => {
      if (historyCache[solveType]?.[progressOrAssignmentId]) {
        return historyCache[solveType][progressOrAssignmentId];
      }
      const { data, error } = await supabase.rpc('new_get_solve_data', {
        solvetype: solveType,
        progress_or_assignment_id: progressOrAssignmentId,
      });

      if (error) throw error;

      setHistoryCache((prev) => ({
        ...prev,
        [solveType]: { ...prev[solveType], [progressOrAssignmentId]: data },
        individual: {
          ...prev.individual,
          ...data.reduce((acc, solve) => {
            acc[solve.history_id] = solve;
            return acc;
          }, {}),
        },
      }));
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyCache.clinic, historyCache.workbook, historyCache.individual]
  );

  // getIndividualHistory : 특정 문제의 풀이 기록을 가져옴.
  const getIndividualHistory = useCallback(
    async (student_question_solve_history_id) => {
      if (historyCache.individual?.[student_question_solve_history_id]) {
        return historyCache.individual[student_question_solve_history_id];
      }

      const { data, error } = await supabase.rpc('new_get_individual_history', {
        individual_history_id: student_question_solve_history_id,
      });
      if (error) throw error;
      setHistoryCache((prev) => ({
        ...prev,
        individual: { ...prev.individual, [student_question_solve_history_id]: data },
      }));
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyCache.clinic, historyCache.workbook, historyCache.individual]
  );

  // sendDMMessage : 특정 DM 세션에 메시지를 보냄.
  const sendDMMessage = useCallback(async (sessionId, content) => {
    const { error } = await supabase.rpc('new_send_dm_message', {
      session_id: sessionId,
      content_input: content,
    });
    if (error) throw error;
  }, []);

  // getDMSessionMessages : 특정 DM 세션의 메시지를 가져옴.
  const getDMSessionMessages = useCallback(async (sessionId) => {
    const { data, error } = await supabase.rpc('new_get_dm_messages', {
      dm_session_id_input: sessionId,
    });
    if (error) throw error;
    return data;
  }, []);

  // getAiSessionMessages : 특정 AI 세션의 메시지를 가져옴.
  const getAiTutorSessionMessages = useCallback(async (sessionId) => {
    const { data, error } = await supabase.rpc('new_get_ai_tutor_messages', {
      ai_tutor_session_id_input: sessionId,
    });
    if (error) throw error;
    return data;
  }, []);

  // getStudentSolvedCurriculumList : 학생이 푼 워크북 목록을 가져옴.
  const getSolvedCurriculumList = useCallback(async (user_id) => {
    // user_id가 없으면 현재 로그인한 유저의 정보를 가져옴
    let userId;
    if (!user_id) {
      const userData = await supabase.auth.getUser();
      userId = userData?.data?.user?.id;
    } else {
      userId = user_id;
    }
    const { data, error } = await supabase.rpc('get_student_solved_curriculum_list', {
      student_uuid: userId,
    });
    if (error) throw error;
    return data;
  }, []);

  // getStudentSolvedCurriculumList : 학생이 푼 워크북 목록을 가져옴.
  const getStudentCurriculumReport = useCallback(async (class_curriculum_id, user_id) => {
    let userId;
    if (!user_id) {
      const userData = await supabase.auth.getUser();
      userId = userData.data.user.id;
    } else {
      userId = user_id;
    }

    const { data, error } = await supabase.rpc('get_student_curriculum_report', {
      class_curriculum_id_input: class_curriculum_id,
      user_id_input: userId,
    });
    if (error) throw error;
    return data;
  }, []);

  const getTotalReport = useCallback(async (user_id) => {
    let userId;
    if (!user_id) {
      const userData = await supabase.auth.getUser();
      userId = userData.data.user.id;
    } else {
      userId = user_id;
    }
    const { data, error } = await supabase.rpc('get_total_report', {
      student_id_input: userId,
    });
    if (error) throw error;
    return data;
  }, []);

  // getSeasonReportComment: 시즌 보고서 코멘트를 가져옴
  const getSeasonReportComment = useCallback(async (studentId, classCurriculumId) => {
    if (!studentId) {
      const userData = await supabase.auth.getUser();
      if (!userData.data || !userData.data.user || !userData.data.user.id) {
        throw new Error('유저 정보를 가져오는데 실패했습니다. 다시 로그인해주세요.');
      }
      studentId = userData.data.user.id;
    }
    const { data, error } = await supabase
      .from('season_report_comment')
      .select('teacher_comment')
      .eq('student_id', studentId)
      .eq('class_curriculum_id', classCurriculumId);
    if (error) throw error;
    return data;
  }, []);

  // getWeeklyReportComment: 주간 보고서 코멘트를 가져옴
  const getWeeklyReportComment = useCallback(async (studentId, classCurriculumId, weekIndex) => {
    if (!studentId) {
      const userData = await supabase.auth.getUser();
      if (!userData.data || !userData.data.user || !userData.data.user.id) {
        throw new Error('유저 정보를 가져오는데 실패했습니다. 다시 로그인해주세요.');
      }
      studentId = userData.data.user.id;
    }
    const { data, error } = await supabase
      .from('weekly_report_comment')
      .select('teacher_comment')
      .eq('student_id', studentId)
      .eq('class_curriculum_id', classCurriculumId)
      .eq('week_index', weekIndex);
    if (error) throw error;
    return data;
  }, []);

  // upsertUserAvatar : 유저의 프로필 사진을 업데이트함

  const upsertUserAvatar = useCallback(async (file) => {
    try {
      const userData = await supabase.auth.getUser();
      const userId = userData?.data?.user?.id;
      const { data, error } = await supabase.storage.from('avatar').upload(`${userId}.jpg`, file, {
        cacheControl: '3600',
        upsert: true,
      });
      dispatch({
        type: 'UPDATE_AVATAR',
        payload: {
          avatarURL: URL.createObjectURL(file),
        },
      });

      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      console.error('Error uploading user avatar', error);
      throw error;
    }
  }, []);

  const checkMessageRead = useCallback(async (msg_id, mode) => {
    const { data, error } = await supabase.rpc('check_message_read', {
      msg_id,
      mode,
    });
    if (error) throw error;
    return data;
  }, []);

  // 학생용 함수들

  // getStudentInfo : 프로그램 전역에서 쓰이는 학생 정보를 가져오는 함수
  const getStudentInfo = useCallback(async () => {
    const { data, error } = await supabase.rpc('new_get_student_dashboard');
    if (error) throw error;
    return data;
  }, []);

  // getCurriculumQuestion : 특정 워크북, 특정 날짜의 문제를 가져옴. 주의) classCurriculumId가 아닌 curriculumId를 넣어야 함
  const getCurriculumQuestion = useCallback(
    async (curriculumId, weekNumber, dayNumber) => {
      const { data, error } = await supabase.rpc(`get_curriculum_context_questions`, {
        _curriculum_id: curriculumId,
        _week_index: weekNumber,
        _day_index: dayNumber,
      });
      if (error) throw error;
      // 캐싱을 위해 questionCache에 저장
      if (data?.length > 0) {
        const newCache = data.reduce(
          (acc, context) => {
            acc[context.context_id] = context;
            return acc;
          },
          { ...questionCache }
        );
        setQuestionCache(newCache);
      }
      return data;
    },

    [questionCache]
  );
  // getClinicQuestion : 특정 클리닉의 문제를 가져옴. 이 함수는
  const getClinicQuestion = useCallback(
    async (assignId) => {
      const { data, error } = await supabase.rpc('get_weakness_assignment_context_question', {
        _assign_id: assignId,
      });
      if (error) throw error;

      // 캐싱을 위해 questionCache에 저장
      if (data?.length > 0) {
        const newCache = data.reduce(
          (acc, context) => {
            acc[context.context_id] = context;
            return acc;
          },
          { ...questionCache }
        );
        setQuestionCache(newCache);
      }
      return data;
    },
    [questionCache]
  );

  // submitWorkbook : 특정 워크북, 특정 날짜의 문제를 풀이함. 주의) 위 함수와 다르게 이곳에는 classCurriculumId를 넣어야 함
  const submitWorkbook = useCallback(
    async (solveDetails, classCurriculumId, weekIndex, dayIndex) => {
      const { data, error } = await supabase.rpc('new_submit_workbook_solve', {
        solvedetails: solveDetails,
        classcurriculumid: classCurriculumId,
        weekindex: weekIndex,
        dayindex: dayIndex,
      });

      if (error) throw error;
      if (!data) throw new Error('예상하지 못한 오류가 발생했습니다. 다시 시도해주세요.');
      const { result, progressHistoryId, clinic_id } = data;
      dispatch({
        type: 'SUBMIT_WORKBOOK',
        payload: {
          day_index: dayIndex,
          week_index: weekIndex,
          progress_history_id: progressHistoryId,
        },
      });
      setHistoryCache((prev) => ({
        ...prev,
        workbook: { ...prev.workbook, [progressHistoryId]: result },
      }));
      if (clinic_id) {
        dispatch({
          type: 'NEW_CLINIC_ASSIGNED',
          payload: {
            clinic_id,
            week_index: weekIndex,
            day_index: dayIndex,
            assigned_date: new Date().toISOString(),
            title: state.user.studentInfo?.curriculum_info?.title || '클리닉',
          },
        });
      }
      return result;
    },
    [state?.user?.studentInfo?.curriculum_info?.title]
  );

  // submitClinic : 특정 클리닉의 문제를 풀이함.
  const submitClinic = useCallback(async (assignId, solveDetails) => {
    try {
      const { data, error } = await supabase.rpc('submit_weakness_solve', {
        _assign_id: assignId,
        _solve_details: solveDetails,
      });
      if (error) throw error;
      if (data) {
        dispatch({
          type: 'SUBMIT_CLINIC',
          payload: {
            clinic_id: assignId,
          },
        });
        setHistoryCache((prev) => ({
          ...prev,
          clinic: { ...prev.clinic, [assignId]: data },
        }));
        return data;
      }
      return data;
    } catch (error) {
      console.log(error);
      console.log(error.message);
      throw error;
    }
  }, []);

  // startDirectMessage : 선생님과의 채팅을 시작함.
  const startDirectMessage = useCallback(async (historyId) => {
    const { data, error } = await supabase.rpc('new_start_new_dm', {
      history_id_input: historyId,
    });
    if (error) throw error;
    return data;
  }, []);

  // startAiChat : AI튜터와의 채팅을 시작함.
  const startAiChat = useCallback(
    async (solveHistoryId) => {
      const { data, error } = await supabase.rpc('start_new_ai_chat', {
        input_solve_history_id: solveHistoryId,
      });
      if (error) throw error;
      return data;
    },

    []
  );

  // studentGetDMSessions : 학생용으로 선생님과의 채팅방 목록을 가져옴.
  const studentGetDMSessions = useCallback(async () => {
    const { data, error } = await supabase.rpc('new_get_student_dm_sessions');
    if (error) throw error;
    return data;
  }, []);

  // studentGetAiSessions : 학생용으로 AI와의 채팅방 목록을 가져옴.
  const studentGetAiSessions = useCallback(async () => {
    const { data, error } = await supabase.rpc('new_get_student_ai_sessions');
    if (error) throw error;
    return data;
  }, []);

  // sendAiTutorMessage : AI튜터에게 메시지를 보냄.
  const sendAiTutorMessage = useCallback(async (sessionId, content) => {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;

    const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/send-ai-chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.session.access_token}`,
      },
      body: JSON.stringify({
        query: content,
        sessionId,
      }),
    });

    if (!response.ok) {
      const errorBody = await response.json();
      throw new Error(errorBody.error || '알 수 없는 오류가 발생했습니다.');
    }
    dispatch({
      type: 'CREDIT_CHANGED',
      payload: {
        credit: -1,
      },
    });
    return response.body; // This is a ReadableStream
  }, []);

  const sendAiTutorMessageClaude = useCallback(async (sessionId, content, modelName) => {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;

    const response = await fetch(
      `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/claude-send-ai-chat`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.session.access_token}`,
        },
        body: JSON.stringify({
          query: content,
          sessionId,
          modelName,
        }),
      }
    );

    if (!response.ok) {
      const errorBody = await response.json();
      throw new Error(errorBody.error || '알 수 없는 오류가 발생했습니다.');
    }
    dispatch({
      type: 'CREDIT_CHANGED',
      payload: {
        credit: -1,
      },
    });
    return response.body; // This is a ReadableStream
  }, []);

  // requestTeacherConnection : 선생님에게 연결 요청을 보냄.
  const requestTeacherConnection = useCallback(async (teacherCode) => {
    try {
      const { data, error } = await supabase.rpc('request_teacher_connection', {
        teacher_code: teacherCode,
      });
      const { error: refreshError } = await supabase.auth.refreshSession();
      if (error) throw error;
      if (refreshError) throw refreshError;
      return data;
    } catch (error) {
      console.error('Error requesting teacher connection', error);
      throw error;
    }
  }, []);

  // getStudentDirectModeMessages : 학생용으로 선생님과의 그냥채팅 모드 메시지를 가져옴.
  const getStudentDirectModeMessages = useCallback(async () => {
    const { data, error } = await supabase.rpc('new_get_student_direct_mode_messages');
    if (error) throw error;
    return data;
  }, []);

  //

  const sendStudentDirectModeMessage = useCallback(async (content) => {
    const { data, error } = await supabase.rpc('new_send_student_direct_mode_messages', {
      content,
    });
    if (error) throw error;
    return data;
  }, []);
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.user_id ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: {
        ...state.user,
      },
      method: 'supabase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      authInitialized: state.authInitialized,
      //
      login,
      register,
      logout,
      forgotPassword,
      updatePassword,
      // 공통 함수
      getContextQuestionData,
      getSolveData,
      getIndividualHistory,
      getDMSessionMessages,
      getAiTutorSessionMessages,
      sendDMMessage,
      getSolvedCurriculumList,
      getStudentCurriculumReport,
      getTotalReport,
      getSeasonReportComment,
      getWeeklyReportComment,
      upsertUserAvatar,
      checkMessageRead,
      // 학생용 함수
      getStudentInfo,
      getCurriculumQuestion,
      getClinicQuestion,
      submitWorkbook,
      submitClinic,
      startDirectMessage,
      studentGetDMSessions,
      studentGetAiSessions,
      sendAiTutorMessage,
      sendAiTutorMessageClaude,
      startAiChat,
      requestTeacherConnection,
      getStudentDirectModeMessages,
      sendStudentDirectModeMessage,
    }),
    [
      forgotPassword,
      login,
      logout,
      updatePassword,
      register,
      // 공통 함수
      getContextQuestionData,
      getSolveData,
      getIndividualHistory,
      getDMSessionMessages,
      getAiTutorSessionMessages,
      sendDMMessage,
      getSolvedCurriculumList,
      getStudentCurriculumReport,
      getTotalReport,
      getSeasonReportComment,
      getWeeklyReportComment,
      upsertUserAvatar,
      checkMessageRead,
      // 학생용 함수
      getStudentInfo,
      getCurriculumQuestion,
      getClinicQuestion,
      submitWorkbook,
      submitClinic,
      startDirectMessage,
      studentGetDMSessions,
      studentGetAiSessions,
      sendAiTutorMessage,
      sendAiTutorMessageClaude,
      startAiChat,
      requestTeacherConnection,
      getStudentDirectModeMessages,
      sendStudentDirectModeMessage,
      //
      state.user,
      status,
      state.authInitialized,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
