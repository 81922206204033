import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, FunctionGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

const StudentLearnMyworkbook = lazy(() => import('src/pages/student/learn/myworkbook'));
const StudentLearnClinic = lazy(() => import('src/pages/student/learn/clinic'));

const StudentMessageAITutor = lazy(() => import('src/pages/student/message/aitutor'));
const StudentMessageTeacher = lazy(() => import('src/pages/student/message/teacher'));

const StudentHistoryReport = lazy(() => import('src/pages/student/history/report'));
const StudentHistoryAnalysis = lazy(() => import('src/pages/student/history/analysis'));

const StudentLearnSolveClinic = lazy(() => import('src/pages/student/learn/solve/clinic'));
const StudentLearnSolveWorkbook = lazy(() => import('src/pages/student/learn/solve/workbook'));

// ----------------------------------------------------------------------

const learn = {
  path: 'learn',
  children: [
    {
      path: 'myworkbook',
      element: <StudentLearnMyworkbook />,
    },
    {
      path: 'clinic',
      element: <StudentLearnClinic />,
    },
    {
      path: 'solve',
      children: [
        {
          path: 'clinic',
          children: [
            {
              path: ':clinicId',
              element: <StudentLearnSolveClinic />,
            },
          ],
        },
        {
          path: 'workbook',
          element: <StudentLearnSolveWorkbook />,
        },
      ],
    },
  ],
};

const message = {
  path: 'message',
  children: [
    {
      path: 'aitutor',
      element: <StudentMessageAITutor />,
    },
    {
      path: 'teacher',
      element: <StudentMessageTeacher />,
    },
  ],
};

const history = {
  path: 'history',
  children: [
    {
      path: 'report',
      element: <StudentHistoryReport />,
    },
    {
      path: 'analysis',
      element: <StudentHistoryAnalysis />,
    },
  ],
};

export const studentRoutes = [
  {
    path: 'student',
    element: (
      <AuthGuard>
        <FunctionGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </FunctionGuard>
      </AuthGuard>
    ),
    children: [learn, message, history],
  },
];
