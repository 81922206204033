import { m } from 'framer-motion';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Logo from 'src/components/logo/logo';
import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function UnexpectedErrorView() {
  return (
    <Card sx={{ py: 22.5, borderRadius: '30px' }}>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Logo
            width={120}
            sx={{
              my: { xs: 2, sm: 4 },
            }}
          />
        </m.div>
        <m.div variants={varBounce().in}>
          <Typography variant="h25" fontWeight={400} color="primary.main">
            예상하지 못한 오류가 발생했습니다.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography color="primary.main" sx={{ mt: 4 }} variant="body1">
            오류 보고서가 자동으로 서버에 전송되었습니다. <br />
            검토 후 빠른 시일 내에 조치하겠습니다. <br />
            불편을 드려 죄송합니다.
          </Typography>
        </m.div>

        <Button href="/" size="large" variant="contained" sx={{ mt: 5, px: 4, borderRadius: 1.5 }}>
          홈으로 가기
        </Button>
      </MotionContainer>
    </Card>
  );
}
