import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import Logo from 'src/components/full-logo';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, image, title }) {
  const mdUp = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 640,
        px: { xs: 2, md: 0 },
        display: 'flex',
      }}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack flexGrow={1} spacing={10} alignItems="center" justifyContent="center">
      {title && (
        <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center', mb: -10 }}>
          {title}
        </Typography>
      )}

      <Box
        sx={{
          maxWidth: {
            xs: 400,
            lg: 430,
            xl: 430,
          },
        }}
      >
        <Stack spacing={8.5}>
          {/* KEY POINT 1 */}
          <Stack direction="column" alignItems="start" spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip label="POINT 1" color="primary" size="small" sx={{ borderRadius: '20px' }} />
              <Typography noWrap variant="h45" fontWeight={500} color="primary">
                시간 낭비 STOP!
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography noWrap variant="body1" fontWeight={400}>
                또 학원? NO!&nbsp;
              </Typography>
              <Typography noWrap variant="body1" color="primary" fontWeight={400}>
                매일 15분&nbsp;
              </Typography>
              <Typography noWrap variant="body1" fontWeight={400}>
                자기주도학습으로 영어 1등급을 준비하세요
              </Typography>
            </Stack>
          </Stack>

          {/* KEY POINT 2 */}
          <Stack direction="column" alignItems="start" spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip label="POINT 2" color="primary" size="small" sx={{ borderRadius: '20px' }} />
              <Typography noWrap variant="h45" fontWeight={500} color="primary">
                초.중.고 모두 OK!
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography noWrap variant="body1" fontWeight={400}>
                고등 뿐만 아니라&nbsp;
              </Typography>
              <Typography noWrap variant="body1" color="primary" fontWeight={400}>
                초.중등
              </Typography>
              <Typography noWrap variant="body1" fontWeight={400}>
                도&nbsp;
              </Typography>
              <Typography noWrap variant="body1" color="primary" fontWeight={400}>
                고등수능자료 퀄리티
              </Typography>
              <Typography noWrap variant="body1" fontWeight={400}>
                를 누리세요.
              </Typography>
            </Stack>
          </Stack>

          {/* KEY POINT 3 */}
          <Stack direction="column" alignItems="start" spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip label="POINT 3" color="primary" size="small" sx={{ borderRadius: '20px' }} />
              <Typography noWrap variant="h45" fontWeight={500} color="primary">
                질문 부담 ZERO!
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography noWrap variant="body1" fontWeight={400}>
                혼자 또는 학원에서 하기 힘든 질문하기,&nbsp;
              </Typography>
              <Typography noWrap variant="body1" color="primary" fontWeight={400}>
                AI튜터
              </Typography>
              <Typography noWrap variant="body1" fontWeight={400}>
                로 해결하세요.
              </Typography>
            </Stack>
          </Stack>

          {/* KEY POINT 4 */}
          <Stack direction="column" alignItems="start" spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip label="POINT 4" color="primary" size="small" sx={{ borderRadius: '20px' }} />
              <Typography variant="h45" fontWeight={500} color="primary">
                안심 관리 PLUS!
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body1" color="primary" fontWeight={400} noWrap>
                SKY멘토
              </Typography>
              <Typography variant="body1" fontWeight={400}>
                와&nbsp;
              </Typography>
              <Typography variant="body1" color="primary" fontWeight={400} noWrap>
                전문강사
              </Typography>
              <Typography variant="body1" fontWeight={400} noWrap>
                의 관리로 자기주도학습 습관을 완성하세요.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {renderContent}
      {mdUp && renderSection}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
