import React from 'react';

import { Box } from '@mui/material';

import Image from './solve.png';

const OPACITY = 0;
const OverlayManager = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundImage: `url(${Image})`,
      backgroundSize: 'cover',
      opacity: OPACITY,
      pointerEvents: 'none',
      zIndex: 1300, // MUI's Modal has zIndex 1300, adjust if necessary
    }}
  />
);

export default OverlayManager;
