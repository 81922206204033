import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const TeacherAcademyStudent = lazy(() => import('src/pages/teacher/academy/student'));
const TeacherAcademyClinic = lazy(() => import('src/pages/teacher/academy/clinic'));
const TeacherAcademyClassroom = lazy(() => import('src/pages/teacher/academy/classroom'));
const TeacherWorksheetList = lazy(() => import('src/pages/teacher/worksheet/list'));
const TeacherWorksheetExamsheet = lazy(() => import('src/pages/teacher/worksheet/examsheet'));
const TeacherWorksheetHomeworksheet = lazy(
  () => import('src/pages/teacher/worksheet/homeworksheet')
);
const TeacherQuestionbankList = lazy(() => import('src/pages/teacher/questionbank/list'));
const TeacherQuestionbankUpload = lazy(() => import('src/pages/teacher/questionbank/upload'));

// ----------------------------------------------------------------------

const academy = {
  path: 'academy',
  children: [
    {
      path: 'student',
      element: <TeacherAcademyStudent />,
    },
    {
      path: 'clinic',
      element: <TeacherAcademyClinic />,
    },
    {
      path: 'classroom',
      element: <TeacherAcademyClassroom />,
    },
  ],
};

const worksheet = {
  path: 'worksheet',
  children: [
    {
      path: 'list',
      element: <TeacherWorksheetList />,
    },
    {
      path: 'examsheet',
      element: <TeacherWorksheetExamsheet />,
    },
    {
      path: 'homeworksheet',
      element: <TeacherWorksheetHomeworksheet />,
    },
  ],
};

const questionbank = {
  path: 'questionbank',
  children: [
    {
      path: 'list',
      element: <TeacherQuestionbankList />,
    },
    {
      path: 'upload',
      element: <TeacherQuestionbankUpload />,
    },
  ],
};

export const teacherRoutes = [
  {
    path: 'teacher',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [academy, worksheet, questionbank],
  },
];
