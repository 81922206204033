import { Helmet } from 'react-helmet-async';

import { UnexpectedErrorView } from 'src/sections/error';

// ----------------------------------------------------------------------

export default function UnexpectedErrorPage() {
  return (
    <>
      <Helmet>
        <title>오류</title>
      </Helmet>

      <UnexpectedErrorView />
    </>
  );
}
