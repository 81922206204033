/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

// ----------------------------------------------------------------------

import Router from 'src/routes/sections';

import ThemeProvider from 'src/theme';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';

import { AuthProvider } from 'src/auth/context/supabase';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { LocalizationProvider } from 'src/locales';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import CompactLayout from 'src/layouts/compact';
import OverlayManager from './overlay';
import UnexpectedErrorPage from './pages/unexpected-error';
// ----------------------------------------------------------------------
Sentry.init({
  dsn: 'https://e2b80613542fa6d239b5ca7dea73a606@o4506415586476032.ingest.us.sentry.io/4506902393126912',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/myworkbook\.ai/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
const router = createBrowserRouter([{ path: '*', Component: Root }]);
export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  useScrollToTop();

  return (
    <AuthProvider>
      <OverlayManager />
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light',
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <Sentry.ErrorBoundary
              fallback={
                <CompactLayout>
                  <UnexpectedErrorPage />
                </CompactLayout>
              }
            >
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <Router />
                </SnackbarProvider>
              </MotionLazy>
            </Sentry.ErrorBoundary>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
