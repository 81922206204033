import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import HomeLayout from 'src/layouts/home';
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('src/pages/404'));
const PageHome = lazy(() => import('src/pages/home'));
const PageRedirectRequest = lazy(() => import('src/pages/redirect-request'));
const TermsAndConditionsPage = lazy(() => import('src/pages/terms-and-conditions'));
const UnexpectedErrorPage = lazy(() => import('src/pages/unexpected-error'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: <Outlet />,
    children: [
      {
        path: 'home',
        element: (
          <HomeLayout>
            <PageHome />
          </HomeLayout>
        ),
      },
      {
        path: 'terms-and-conditions',
        element: (
          <HomeLayout>
            <TermsAndConditionsPage />
          </HomeLayout>
        ),
      },
      {
        path: '404',
        element: (
          <CompactLayout>
            <Page404 />
          </CompactLayout>
        ),
      },
      {
        path: 'redirect-request',
        element: (
          <CompactLayout>
            <PageRedirectRequest />
          </CompactLayout>
        ),
      },
      {
        path: 'unexpected-error',
        element: (
          <CompactLayout>
            <UnexpectedErrorPage />
          </CompactLayout>
        ),
      },
    ],
  },
];
